import { html, useState } from "haunted";
import { HauntedFunc } from "Shared/haunted/HooksHelpers";
import { getParsedProperty } from "Services/common";
import { COUNTRY_MAP } from "Services/constants";

export const useShadowDOM = false;
export const name = "ac-insurance-tab-settings";

export const observedAttributes: (keyof Attributes)[] = ["model"];

const CHECKBOX_ELEM_PREFIX = "dynamic-insurance-tab-checkbox-";
const INPUT_ELEM_PREFIX = "dynamic-insurance-tab-input-";

export interface Attributes {
	model: string;
}

interface InsuranceTabSetting {
	index: number;
	culture: string;
	country: string;
	isActive: boolean;
	url: string;
}

export interface Props {
	model: InsuranceTabSetting[];
}

export const Component: HauntedFunc<Props> = (host) => {
	const parseModel = () => {
		const hostModel =
			typeof host.model === "string" ? getParsedProperty<DynamicInsuranceTabSetting[]>(host.model) : [];

		return Array.from(COUNTRY_MAP.keys()).map((culture, index): InsuranceTabSetting => {
			const setting = hostModel?.find((s) => s.Culture.toLowerCase() === culture.toLowerCase());

			return {
				index,
				culture: setting?.Culture ?? culture,
				country: COUNTRY_MAP.get(culture),
				isActive: setting?.IsActive ?? false,
				url: setting?.Url ?? "",
			};
		});
	};

	// HELPER FUNCTIONS

	// EVENT HANDLERS

	// COMPONENT

	const handleCheckboxChange = (e: KeyboardEvent, index: number) => {
		e.preventDefault();
		e.stopPropagation();

		const checkbox = e.target as HTMLInputElement;

		setInsuranceSettings((prevSettings) =>
			prevSettings.map((setting) =>
				setting.index === index ? { ...setting, isActive: checkbox.checked } : setting
			)
		);
	};

	const handleSave = (e: MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		(document.getElementById("actionType") as HTMLInputElement).value = "save_dynamic_settings";
		(document.getElementById("admin_form") as HTMLFormElement).submit();
	};

	// COMPONENT

	const [insuranceSettings, setInsuranceSettings] = useState<InsuranceTabSetting[]>(parseModel());

	// TEMPLATES

	const rowTemplate = (setting: InsuranceTabSetting) => {
		return html`
			<input
				type="hidden"
				name=${`DynamicInsuranceTabSettings[${setting.index}].Culture`}
				value=${setting.culture}
			/>
			<div class="row">
				<div class="col-xs-1 col-md-2-5">
					<input
						type="checkbox"
						name=${`DynamicInsuranceTabSettings[${setting.index}].IsActive`}
						id=${`${CHECKBOX_ELEM_PREFIX}${setting.culture.split("-")[1]}`}
						value=${setting.isActive}
						?checked=${setting.isActive}
						@change=${(e: KeyboardEvent) => handleCheckboxChange(e, setting.index)}
					/>
					<label class="push-down" for=${`${CHECKBOX_ELEM_PREFIX}${setting.culture.split("-")[1]}`}>
						Show Insurance Tab for ${setting.country}
					</label>
				</div>

				<div class="col-xs-1 col-md-3-5">
					<label for=${`${INPUT_ELEM_PREFIX}${setting.culture.split("-")[1]}`}> Button Url </label>
					<input
						type="text"
						name=${`DynamicInsuranceTabSettings[${setting.index}].Url`}
						id=${`${INPUT_ELEM_PREFIX}${setting.culture.split("-")[1]}`}
						value=${setting.url}
					/>
				</div>
			</div>
		`;
	};

	const submitButtonTemplate = () => html`
		<div class="row">
			<div class="col-xs-1 col-md-4-5">
				<div class="sj-save-warning">
					* After saving, a page refresh (F5) may be necessary to properly reflect the saved state.
				</div>
			</div>
			<div class="col-xs-1 col-sm-1-5 main-buttons">
				<button type="submit" value="Save" class="btn-primary push-down" @click=${handleSave}>Save</button>
			</div>
		</div>
	`;

	return html` ${insuranceSettings.map(rowTemplate)} ${submitButtonTemplate()} `;
};
