import { CLASS_NAMES } from "Services/ClassNames";
import { useEffect, useState } from "Shared/haunted/CustomHooks";
import { filterCountriesForPartialString, getCountryAndStationByCode } from "ComponentHelpers/StationHelper";
import { html, useRef } from "haunted";
import i18next from "i18next";
import DomCrawlingHelper from "Services/DomCrawlingHelper";
import PerfectScrollbar from "perfect-scrollbar";
import { ref } from "Components/directives/ref";
import { usePane } from "../opening-pane/usePane";
import { isMobileResolution } from "Services/common";
import { TestIdDictionary } from "Services/test-ids/TestIdDictionary";
import { getTestId } from "Services/test-ids/TestIdHelper";
import { PUB_SUBS } from "Services/pub-sub-service/PubSub";
import { useStations } from "ComponentHelpers/useStations";
import { ApiCountry, ApiRegion, ApiStation } from "Shared/models/ApiStations";
import classNames from "classnames";
import {
	eventBusTriggerOriginSelected,
	eventBusTriggerDestinationSelected,
} from "ComponentHelpers/SearchboxEventBusHelpers";
import { useUserInfo } from "ComponentHelpers/useUserInfo";

export interface Props {
	culture: string;
	currentPromoCode: string;
	defaultRouteCountrySettings: DefaultRouteCountrySetting[];
	setIsCollapsed: (newState: boolean) => void;
	startLoading: () => LoaderInstance;
	stopLoading: (loader: any) => void;
}

const listItemClassName = "dg-ts-list-item";

export const useRouteSelector = (props: Props) => {
	const pane = usePane();
	const userInfo = useUserInfo();

	const getDefaultCountry = (endpoint: "origin" | "destination"): string =>
		props.defaultRouteCountrySettings
			?.find((setting) => setting.Culture.toLowerCase() === props.culture.toLowerCase())
			?.[endpoint === "origin" ? "DefaultOriginCountryCode" : "DefaultDestinationCountryCode"]?.toUpperCase() ||
		"";

	const citySelector = useRef<HTMLUListElement>(undefined);
	const countryList = useRef<HTMLUListElement>(undefined);
	const fromCity = useRef<HTMLInputElement>(undefined);
	const locationSelector = useRef<HTMLDivElement>(undefined);
	const mobileList = useRef<HTMLUListElement>(undefined);
	const mobileSearchField = useRef<HTMLInputElement>(undefined);
	const toCity = useRef<HTMLInputElement>(undefined);
	const typingResults = useRef<HTMLUListElement>(undefined);

	const [displayedData, setDisplayedData] = useState<ApiCountry[]>([]);
	const [isValidated, setIsValidated] = useState<boolean>(false);
	const [searchExpressionDestination, setSearchExpressionDestination] = useState<string>("");
	const [searchExpressionOrigin, setSearchExpressionOrigin] = useState<string>("");
	const [selectedDestination, setSelectedDestination] = useState<ApiStation>(undefined);
	const [selectedDestinationCountryCode, setSelectedDestinationCountryCode] = useState<string>(
		getDefaultCountry("destination")
	);
	const [selectedOrigin, setSelectedOrigin] = useState<ApiStation>(undefined);
	const [selectedOriginCountryCode, setSelectedOriginCountryCode] = useState<string>(getDefaultCountry("origin"));

	const [cityScroller, setCityScroller] = useState<PerfectScrollbar>(undefined);
	const [countryScroller, setCountryScroller] = useState<PerfectScrollbar>(undefined);
	const [mobileListScroller, setMobileListScroller] = useState<PerfectScrollbar>(undefined);
	const [typingResultScroller, setTypingResultScroller] = useState<PerfectScrollbar>(undefined);

	const [allCountries, availableDestinationCountriesForStation] = useStations({
		opts: { culture: props.culture },
		startLoading: () => props.startLoading(),
		stopLoading: (loader) => props.stopLoading(loader),
	});

	// Helpers

	const validate = () => {
		setIsValidated(true);
		return Boolean(selectedOrigin) && Boolean(selectedDestination);
	};

	const handleOriginSelect = (city: ApiStation) => {
		eventBusTriggerOriginSelected(city);
		setSelectedOrigin(city);
		setSelectedDestination(undefined);
		setSelectedDestinationCountryCode(undefined);
		openDestinationSelector(undefined, city);
		pane.set("destination");
		clearAllSearchFields();
	};

	const handleDestinationSelect = (city: ApiStation) => {
		eventBusTriggerDestinationSelected(city);

		setSelectedDestination(city);
		pane.set("none");
		clearAllSearchFields();
	};

	const onClickOutsideRoute = (evt: MouseEvent) => {
		const path = evt.composedPath && evt.composedPath();

		const isClickInside =
			path.indexOf(fromCity.current) > -1 ||
			path.indexOf(toCity.current) > -1 ||
			path.indexOf(locationSelector.current) > -1;

		if (!isClickInside && (pane.mode === "origin" || pane.mode === "destination")) {
			pane.set("none");
			clearAllSearchFields();
		}
	};

	const addPerfectScrollbar = () => {
		[countryScroller, cityScroller, typingResultScroller, mobileListScroller].forEach((sc) => sc?.destroy());

		if (countryList?.current) {
			setCountryScroller(instantiatePerfectScrollbar(countryList.current));
			countryList.current.scrollTop = 0;
		}

		if (citySelector?.current) {
			setCityScroller(instantiatePerfectScrollbar(citySelector.current));
			if ((pane.mode === "origin" && !selectedOrigin) || (pane.mode === "destination" && !selectedDestination)) {
				citySelector.current.scrollTop = 0;
			}
		}

		if (typingResults?.current) {
			setTypingResultScroller(instantiatePerfectScrollbar(typingResults.current));
			typingResults.current.scrollTop = 0;
		}

		if (mobileList?.current) {
			setMobileListScroller(instantiatePerfectScrollbar(mobileList.current));
			mobileList.current.scrollTop = 0;
		}
	};

	const instantiatePerfectScrollbar = (element: HTMLElement) =>
		new PerfectScrollbar(element, {
			wheelPropagation: false,
			wheelSpeed: 0.2,
			swipeEasing: true,
			suppressScrollX: true,
		});

	const isSelectedOriginCountry = (countryCode: string) =>
		pane.mode === "origin" && selectedOriginCountryCode && selectedOriginCountryCode === countryCode;

	const isSelectedDestinationCountry = (countryCode: string) =>
		pane.mode === "destination" && selectedDestinationCountryCode && selectedDestinationCountryCode === countryCode;

	const isSelectedOriginCity = (cityCode: string) =>
		pane.mode === "origin" && selectedOrigin && selectedOrigin.information.code === cityCode;

	const isSelectedDestinationCity = (cityCode: string) =>
		pane.mode === "destination" && selectedDestination && selectedDestination.information.code === cityCode;

	const showCitySelector = () =>
		Boolean(
			(pane.mode === "origin" && selectedOriginCountryCode) ||
				(pane.mode === "destination" && selectedDestinationCountryCode)
		);

	const scrollToCity = (code: string) => {
		const elem = isMobileResolution() ? mobileList?.current : citySelector?.current;
		const selectedCityListItem = getSelectedListElem(elem, code);

		if (areContainerAndItemVisible(elem, selectedCityListItem)) {
			elem.scrollTop = 0;
			scrollContainerToItem(elem, selectedCityListItem);
		}
	};

	const scrollToCountry = (code: string) => {
		const elem = isMobileResolution() ? mobileList?.current : countryList?.current;
		const selectedCountryListItem = getSelectedListElem(elem, code);

		if (areContainerAndItemVisible(elem, selectedCountryListItem)) {
			scrollContainerToItem(elem, selectedCountryListItem);
		}
	};

	const getSelectedListElem = (container: HTMLElement, code: string) => {
		if (!container) return undefined;

		// FIXME This is not very elegant
		return DomCrawlingHelper.getArrayOfClass(container, listItemClassName).find(
			(item) => item.dataset.testValue?.toLowerCase() === code.toLowerCase()
		) as HTMLLIElement;
	};

	const areContainerAndItemVisible = (container: HTMLElement, item: HTMLLIElement) =>
		item && item.offsetHeight > 0 && container && container.offsetHeight > 0;

	const scrollContainerToItem = (container: HTMLElement, item: HTMLLIElement) => {
		let counter = 0;

		while (isItemBelowContainer(container, item) && counter < 500) {
			container.scrollTop++;
			counter++;
		}

		while (isItemAboveContainer(container, item) && counter < 500) {
			container.scrollTop--;
			counter++;
		}
	};

	const isItemAboveContainer = (container: HTMLElement, item: HTMLLIElement) =>
		item.getBoundingClientRect().top < container.getBoundingClientRect().top;

	const isItemBelowContainer = (container: HTMLElement, item: HTMLLIElement) =>
		item.getBoundingClientRect().bottom > container.getBoundingClientRect().bottom;

	const regionsToDisplay = () => {
		const code = selectedCountryCode();
		return code ? displayedData?.find((c) => c.information.code === code)?.regions || [] : [];
	};

	const isCountrySelected = (code: string) => isSelectedOriginCountry(code) || isSelectedDestinationCountry(code);

	const isCitySelected = (code: string) => isSelectedOriginCity(code) || isSelectedDestinationCity(code);

	const mobileLocationHeaderText = () =>
		pane.mode === "destination" ? i18next.t("search-box-destination") : i18next.t("search-box-origin");

	const showPane = () => pane.mode === "origin" || pane.mode === "destination";

	const selectedCountryCode = () => {
		if (pane.mode === "destination") return selectedDestinationCountryCode;

		if (pane.mode === "origin") return selectedOriginCountryCode;

		if (pane.mode === "none") {
			return pane.lastOpenMode === "destination" ? selectedDestinationCountryCode : selectedOriginCountryCode;
		}

		return undefined;
	};

	const autoSelectTheOnlyCountry = () => {
		const autoSelectedCountry = displayedData.length === 1 ? displayedData[0].information.code : "";

		if (!autoSelectedCountry) return;

		if (pane.mode === "origin") setSelectedOriginCountryCode(autoSelectedCountry);

		if (pane.mode === "destination") setSelectedDestinationCountryCode(autoSelectedCountry);
	};

	const clearAllSearchFields = () => {
		if (mobileSearchField?.current) mobileSearchField.current.value = "";

		setSearchExpressionOrigin("");
		setSearchExpressionDestination("");
	};

	// Event listeners

	const openOriginSelectorForChange = () => {
		if (mobileSearchField.current) {
			mobileSearchField.current.value = selectedOrigin.information.displayName;
		}

		window.setTimeout(() => {
			scrollToCountry(selectedOriginCountryCode);
			scrollToCity(selectedOrigin.information.code);
			mobileSearchField.current?.focus();
		}, 500);
	};

	const openOriginSelectorEmpty = () => {
		const newSelectedOriginCountryCode = selectedOriginCountryCode || allCountries[0].information.code;
		setSelectedOriginCountryCode(newSelectedOriginCountryCode);

		window.setTimeout(() => {
			scrollToCountry(selectedOriginCountryCode);
		}, 500);

		if (isMobileResolution()) fromCity.current.blur();
	};

	const openOriginSelector = (e?: MouseEvent) => {
		e?.stopPropagation();
		props.setIsCollapsed(false);

		if (searchExpressionOrigin) return;

		pane.set("origin");
		clearAllSearchFields();

		if (selectedOrigin) {
			openOriginSelectorForChange();
		} else {
			openOriginSelectorEmpty();
		}
	};

	const openDestinationSelectorForChange = () => {
		if (mobileSearchField.current) {
			mobileSearchField.current.value = selectedDestination.information.displayName;
		}

		window.setTimeout(() => {
			mobileSearchField.current?.focus();
			scrollToCity(selectedDestination.information.code);
			scrollToCountry(selectedDestinationCountryCode);
		}, 750);
	};

	const openDestinationSelectorEmpty = (city: ApiStation) => {
		const newSelectedDestinationCountryCode =
			selectedDestinationCountryCode || availableDestinationCountriesForStation(city)[0]?.information?.code;

		setSelectedDestinationCountryCode(newSelectedDestinationCountryCode);

		window.setTimeout(() => {
			scrollToCountry(newSelectedDestinationCountryCode);

			if (isMobileResolution()) {
				toCity.current.blur();
			}
		}, 750);
	};

	const openDestinationSelector = (e: MouseEvent, city = selectedOrigin) => {
		e?.stopPropagation();

		if (searchExpressionDestination) return;

		pane.set("destination");
		clearAllSearchFields();

		if (selectedDestination) {
			openDestinationSelectorForChange();
		} else {
			openDestinationSelectorEmpty(city);
		}
	};

	const handleMobileLocationSelectorClose = (e: MouseEvent) => {
		e.stopPropagation();
		pane.set("none");
		clearAllSearchFields();
	};

	const selectCountry = (e: MouseEvent, country: ApiCountry) => {
		e.preventDefault();
		e.stopPropagation();

		if (mobileList.current) mobileList.current.scrollTop = 0;

		if (pane.mode === "origin") {
			handleOriginCountrySelect(country);
			return;
		}

		handleDestinationCountrySelect(country);
	};

	const handleDestinationCountrySelect = (country: ApiCountry) => {
		if (isMobileResolution() && selectedDestinationCountryCode === country.information.code) {
			setSelectedDestinationCountryCode(undefined);
		} else {
			setSelectedDestinationCountryCode(country.information.code);
		}
	};

	const handleOriginCountrySelect = (country: ApiCountry) => {
		if (isMobileResolution() && selectedOriginCountryCode === country.information.code) {
			setSelectedOriginCountryCode(undefined);
		} else {
			setSelectedOriginCountryCode(country.information.code);
		}
	};

	const selectCity = async (e: MouseEvent, city: ApiStation) => {
		e?.preventDefault();
		e?.stopPropagation();

		clearAllSearchFields();

		if (pane.mode === "origin") handleOriginSelect(city);

		if (pane.mode === "destination") handleDestinationSelect(city);
	};

	const handleTypeInOrigin = () => {
		if (pane.mode !== "origin") {
			pane.set("origin");
		}

		setSearchExpressionOrigin(fromCity.current.value);
		setSelectedOrigin(undefined);
		setSelectedDestination(undefined);
		setSelectedDestinationCountryCode(undefined);
	};

	const handleTypeInDestination = () => {
		if (pane.mode !== "destination") pane.set("destination");

		setSearchExpressionDestination(toCity.current.value);
		setSelectedDestination(undefined);
	};

	const handleTypeInMobile = () => {
		if (pane.mode === "origin") {
			setSearchExpressionOrigin(mobileSearchField.current.value);
			setSelectedOrigin(undefined);
			setSelectedDestination(undefined);
			setSelectedDestinationCountryCode(undefined);
			setSearchExpressionDestination("");
		} else {
			setSearchExpressionDestination(mobileSearchField.current.value);
			setSelectedDestination(undefined);
		}
	};

	const updateOnSearchReload = (originCode: string, destinationCode: string) => {
		if (!originCode || !destinationCode) return;

		const origin = getCountryAndStationByCode(allCountries, originCode);
		const destination = getCountryAndStationByCode(allCountries, destinationCode);

		setSelectedOrigin(origin.station);
		setSelectedOriginCountryCode(origin.countryCode);

		setSelectedDestination(destination.station);
		setSelectedDestinationCountryCode(destination.countryCode);
	};

	useEffect(() => {
		if (pane.mode === "origin") {
			const data = searchExpressionOrigin
				? filterCountriesForPartialString(allCountries, searchExpressionOrigin)
				: allCountries;
			setDisplayedData(data);
		}

		if (pane.mode === "destination" && selectedOrigin) {
			const availableDestinations = availableDestinationCountriesForStation(selectedOrigin);
			const data = searchExpressionDestination
				? filterCountriesForPartialString(availableDestinations, searchExpressionDestination)
				: availableDestinations;
			setDisplayedData(data);
		}
	}, [
		pane.mode,
		allCountries,
		searchExpressionOrigin,
		selectedOrigin,
		selectedOriginCountryCode,
		searchExpressionDestination,
	]);

	useEffect(autoSelectTheOnlyCountry, [displayedData]);

	useEffect(() => {
		window.setTimeout(() => addPerfectScrollbar(), 0);
	}, [pane.mode, displayedData]);

	useEffect(() => {
		if (pane.mode === "origin" || pane.mode === "destination") {
			const sub = PUB_SUBS.DocumentClicked.subscribe((e) => onClickOutsideRoute(e.mouseEvent));
			return () => sub.unsubscribe();
		}
		return () => {};
	}, [pane.mode, fromCity?.current, toCity?.current, locationSelector?.current]);

	useEffect(() => {
		citySelector.current.scrollTop = 0;
	}, [selectedOriginCountryCode, selectedDestinationCountryCode]);

	// Templates

	const inputFieldsTemplate = () => html`
		<div class="dg-route-selector">
			${fromTemplate()}
			<i class="jsh-icon jsh-chevron-right route-icon route-icon-departure"></i>
			${toTemplate()}
			<i class="jsh-icon jsh-chevron-right route-icon route-icon-destination"></i>
			<div class="dg-divider-arrow-route"></div>
		</div>
	`;

	const fromTemplate = () => html`
		<input
			ref=${ref(fromCity)}
			class=${classNames({
				"in-focus": pane.mode === "origin",
				[CLASS_NAMES.error]: isValidated && !selectedOrigin,
			})}
			placeholder=${i18next.t("search-box-origin")}
			autocomplete="off"
			?readonly=${isMobileResolution()}
			tabindex="-1"
			data-test-id=${TestIdDictionary.Route.OriginInput}
			value=${selectedOrigin?.information?.displayName || searchExpressionOrigin}
			.value=${selectedOrigin?.information?.displayName || searchExpressionOrigin}
			@click=${openOriginSelector}
			@keyup=${handleTypeInOrigin}
		/>
	`;

	const toTemplate = () => html`
		<input
			ref=${ref(toCity)}
			class=${classNames({
				"in-focus": pane.mode === "destination",
				[CLASS_NAMES.disabled]: !Boolean(selectedOrigin),
				[CLASS_NAMES.error]: isValidated && !selectedDestination,
			})}
			placeholder=${i18next.t("search-box-destination")}
			autocomplete="off"
			tabindex="-1"
			?readonly=${isMobileResolution()}
			data-test-id=${TestIdDictionary.Route.DestinationInput}
			value=${selectedDestination?.information?.displayName || searchExpressionDestination}
			.value=${selectedDestination?.information?.displayName || searchExpressionDestination}
			@click=${(e: MouseEvent) => openDestinationSelector(e, selectedOrigin)}
			@keyup=${handleTypeInDestination}
		/>
	`;

	const mobileHeaderTemplate = () => html`
		<div class="hidden-sm-up dg-mobile-header">
			<span>${mobileLocationHeaderText()}</span>
			<span @click=${handleMobileLocationSelectorClose}>&times;</span>
		</div>
		<input
			ref=${ref(mobileSearchField)}
			class="hidden-md-up dg-mobile-search"
			placeholder=${i18next.t("search-box-search")}
			@keyup=${handleTypeInMobile}
		/>
		<i class="hidden-md-up jsh-icon jsh-search"></i>
		<span class="visible-sm close-tablet-selector" @click=${handleMobileLocationSelectorClose}>&times;</span>
	`;

	const firstPaneTemplate = () => html`
		<div class="dg-opening-pane-1">
			${desktopCountriesTemplate()} ${mobileCountriesTemplate()} ${searchResultListTemplate()}
		</div>
	`;

	const desktopCountriesTemplate = () =>
		!searchExpressionOrigin && !searchExpressionDestination
			? html`
					<div class="hidden-xs hidden-sm">
						<header>
							<i class="hidden-md jsh-icon jsh-pin"></i>
							<div class="country-title">
								<h1>${i18next.t("search-box-discover-1")}</h1>
							</div>
							<i class="jsh-icon jsh-chevron-right"></i>
						</header>
						<label class="dg-country-selector-label">${i18next.t("search-box-country")}</label>
						<ul
							ref=${ref(countryList)}
							class=${classNames("dg-country-selector-list", {
								"with-redemption-mile-and-promo-code":
									window.JetSmart?.DynamicSettings?.IsAmericanOn && !userInfo?.IsAmericanMember,
							})}
							data-test-id=${TestIdDictionary.Route.CountryList}
						>
							${displayedData.map(desktopCountryTemplate)}
						</ul>
					</div>
			  `
			: "";

	const desktopCountryTemplate = (country: ApiCountry) => html`
		<li
			data-test-value=${country.information.code}
			class=${classNames("dg-country-selector-list-item", listItemClassName, {
				[CLASS_NAMES.active]: isCountrySelected(country.information.code),
			})}
			@click=${(e: MouseEvent) => selectCountry(e, country)}
		>
			<span
				class="dg-country-name"
				data-test-id=${TestIdDictionary.Route.CountryListItem}
				data-test-value=${country.information.code}
			>
				${country.information.name}
			</span>
			<i class="jsh-icon jsh-circle-chevron-right"></i>
		</li>
	`;

	const mobileCountriesTemplate = () =>
		!searchExpressionOrigin && !searchExpressionDestination
			? html`
					<ul
						ref=${ref(mobileList)}
						class="hidden-md-up dg-city-selector-list"
						data-test-id=${getTestId(TestIdDictionary.Route.CityList, { m: true })}
					>
						${mobileCountryItemsTemplate()}
					</ul>
			  `
			: "";

	const mobileCountryItemsTemplate = () =>
		displayedData.map((country) => {
			const id = `sbTypingOpener${country.information.code}`;

			return html`
				<li class=${listItemClassName}>
					<input
						type="checkbox"
						id=${id}
						?checked=${country.information.code === selectedCountryCode()}
						.checked=${country.information.code === selectedCountryCode()}
					/>
					<label
						for=${id}
						class="dg-typing-opener"
						@click=${(e: MouseEvent) => selectCountry(e, country)}
						data-test-id=${TestIdDictionary.Route.CountryListItem}
						data-test-value=${country.information.code}
					>
						${country.information.name}
					</label>
					<ul class="dg-mobile-list">
						${mobileRegionsTemplate(country)}
					</ul>
				</li>
			`;
		});

	const mobileRegionsTemplate = (country: ApiCountry) =>
		country.regions.map(
			(region) => html`${regionLabelTemplate(region, false)} ${citiesTemplate(region, false, true)} `
		);

	const citiesTemplate = (region: ApiRegion, isTypingResult: boolean, isMobile: boolean) =>
		region.stations.map((station) => {
			const tempClassMap = classNames(listItemClassName, {
				[CLASS_NAMES.active]: isCitySelected(station.information.code),
				"dg-mobile-list-item": isMobile,
				"dg-typing-results-list-item": isTypingResult && !isMobile,
				"dg-city-selector-list-item": !isTypingResult && !isMobile,
				"is-new": station.information.isNew,
			});

			return html`
				<li
					class=${tempClassMap}
					data-test-id=${getTestId(TestIdDictionary.Route.CityListItem, { m: isMobile })}
					data-test-value=${station.information.code}
					@click=${(e: MouseEvent) => selectCity(e, station)}
				>
					${station.information.name} <span class="dg-station-code">(${station.information.code})</span>
					${newLabelTemplate(station)}
				</li>
			`;
		});

	const typingResultCountryTemplate = (country: ApiCountry) => html`
		<li class="dg-typing-result-item">
			<label for="sbTypingOpener1">${country.information.name}</label>
			<ul class="dg-typing-results-list">
				${regionsTemplate(country.regions, true)}
			</ul>
		</li>
	`;

	const searchResultListTemplate = () =>
		searchExpressionOrigin || searchExpressionDestination
			? html`
					<ul ref=${ref(typingResults)} class="dg-typing-results">
						${displayedData.map(typingResultCountryTemplate)} ${noSearchResultErrorTemplate()}
					</ul>
			  `
			: "";

	const noSearchResultErrorTemplate = () =>
		displayedData.length === 0
			? html`
					<li class="px-4 py-2 font-body font-bold text-dp-red sm:px-6 sm:py-0 md:p-2">
						<label>${i18next.t("search-box-typing-no-result")}</label>
					</li>
			  `
			: "";

	const newLabelTemplate = (station: ApiStation) =>
		station.information.isNew ? html`<span class="dg-station-new">${i18next.t("new")}</span>` : "";

	const regionLabelTemplate = (region: ApiRegion, isTypingResult: boolean) =>
		region.name
			? html`
					<li
						class=${classNames({
							"dg-typing-region-label": isTypingResult,
							"dg-region-label": !isTypingResult,
						})}
					>
						${region.name}
					</li>
			  `
			: "";

	const regionsTemplate = (regionArray: ApiRegion[], isTypingResult: boolean) =>
		regionArray.map(
			(region) =>
				html`${regionLabelTemplate(region, isTypingResult)} ${citiesTemplate(region, isTypingResult, false)} `
		);

	const secondPaneTemplate = () =>
		!searchExpressionOrigin && !searchExpressionDestination
			? html`
					<div class=${classNames("dg-opening-pane-2 hidden-xs hidden-sm", { open: showCitySelector() })}>
						<label class="dg-city-selector-label">${i18next.t("search-box-city")}</label>
						<ul
							ref=${ref(citySelector)}
							class=${classNames("dg-city-selector-list", {
								"with-redemption-mile-and-promo-code":
									window.JetSmart?.DynamicSettings?.IsAmericanOn && !userInfo?.IsAmericanMember,
							})}
							data-test-id=${TestIdDictionary.Route.CityList}
						>
							${regionsTemplate(regionsToDisplay(), false)}
						</ul>
					</div>
			  `
			: "";

	const paneTemplate = () => html`
		<div ref=${ref(locationSelector)} class=${classNames("dg-location-selector", { open: showPane() })}>
			${mobileHeaderTemplate()} ${firstPaneTemplate()} ${secondPaneTemplate()}
		</div>
	`;

	const htmlTemplate = () => html`${inputFieldsTemplate()} ${paneTemplate()}`;

	return {
		selectedDestination,
		selectedDestinationCountryCode,
		selectedOriginCountryCode,
		selectedOrigin,
		htmlTemplate,
		updateOnSearchReload,
		validate,
	};
};
